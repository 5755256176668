import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import { Navigation } from '.'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, isHome }) => {
    const githubUrl = `https://github.com/maxder`
    const linkedinUrl = `https://www.linkedin.com/in/maxderungs/`
    const site = data.allGhostSettings.edges[0].node

    let isTheme = ``

    if (typeof window !== `undefined`) {
        isTheme = localStorage.theme
    }

    // Sticky header
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 })
    const headerRef = useRef(null)

    // Handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.scrollY > (elTopOffset + elHeight + 300)) {
            setSticky({ isSticky: true, offset: elHeight })
        } else {
            setSticky({ isSticky: false, offset: 0 })
        }
    }

    // Add/remove scroll event listener
    useEffect(() => {
        const header = headerRef.current.getBoundingClientRect()
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
        }

        window.addEventListener(`scroll`, handleScrollEvent)

        return () => {
            window.removeEventListener(`scroll`, handleScrollEvent)
        }
    },[]
	)
	
    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600&display=swap" rel="stylesheet" />
                <body className={isTheme} />
                <body/>
            </Helmet>

            <div className="layout">

                {/* The main header section on top of the screen */}
                <header className={sticky.isSticky ? 'sticky' : ''} ref={headerRef}>
 
                    {/* Logo */}
                    <Link to="/">
                       <img className="header__logo" src={site.logo} alt={site.title} />
					</Link>

                    <Navigation data={site.navigation} />

                </header>

                {/* All the main content gets inserted here, index.js, post.js */}
                <main>

                    { isHome ?
						<div className="banner-figure index" style={{ backgroundImage: `url(./home_banner.jpg)` }}>
							<h1>{site.title}</h1>

							<div>
								<span style={{ color: `rgba(153,153,153,1)` }}>I</span>
								<span style={{ color: `rgba(153,153,153,0.9775)` }}>T</span>
								<span style={{ color: `rgba(153,153,153,0.955)` }}>I</span>
								<span style={{ color: `rgba(153,153,153,0.9325)` }}>L</span>
								<span style={{ color: `rgba(153,153,153,0.91)` }}>, </span>
								<span style={{ color: `rgba(153,153,153,0.8875)` }}>P</span>
								<span style={{ color: `rgba(153,153,153,0.865)` }}>C</span>
								<span style={{ color: `rgba(153,153,153,0.8425)` }}>P</span>
								<span style={{ color: `rgba(153,153,153,0.82)` }}>, </span>
								<span style={{ color: `rgba(153,153,153,0.7975)` }}>C</span>
								<span style={{ color: `rgba(153,153,153,0.775)` }}>N</span>
								<span style={{ color: `rgba(153,153,153,0.7525)` }}>E</span>
								<span style={{ color: `rgba(153,153,153,0.73)` }}>, </span>
								<span style={{ color: `rgba(153,153,153,0.7075)` }}>C</span>
								<span style={{ color: `rgba(153,153,153,0.685)` }}>N</span>
								<span style={{ color: `rgba(153,153,153,0.6625)` }}>A</span>
								<span style={{ color: `rgba(153,153,153,0.64)` }}>, </span>
								<span style={{ color: `rgba(153,153,153,0.6175)` }}>C</span>
								<span style={{ color: `rgba(153,153,153,0.595)` }}>N</span>
								<span style={{ color: `rgba(153,153,153,0.5725)` }}>S</span>
								<span style={{ color: `rgba(153,153,153,0.55)` }}>, </span>
								<span style={{ color: `rgba(153,153,153,0.5275)` }}>A</span>
								<span style={{ color: `rgba(153,153,153,0.505)` }}>I</span>
								<span style={{ color: `rgba(153,153,153,0.4825)` }}>A</span>
								<span style={{ color: `rgba(153,153,153,0.46)` }}>, </span>
								<span style={{ color: `rgba(153,153,153,0.4375)` }}>N</span>
								<span style={{ color: `rgba(153,153,153,0.415)` }}>C</span>
								<span style={{ color: `rgba(153,153,153,0.3925)` }}>A</span>
								<span style={{ color: `rgba(153,153,153,0.37)` }}>R</span>
								<span style={{ color: `rgba(153,153,153,0.3475)` }}>B</span>
								<span style={{ color: `rgba(153,153,153,0.325)` }}>, </span>
								<span style={{ color: `rgba(153,153,153,0.3025)` }}>A</span>
								<span style={{ color: `rgba(153,153,153,0.28)` }}>S</span>
								<span style={{ color: `rgba(153,153,153,0.2575)` }}>L</span>
								<span style={{ color: `rgba(153,153,153,0.235)` }}>A</span>
								<span style={{ color: `rgba(153,153,153,0.2125)` }}>…</span>
							</div>
							<h5>{site.description}</h5>
						</div>
                        :
                        null
                    }

                    {children}
                    
                </main>

                {/* The footer at the very bottom of the screen */}
                <footer>
                    <div className="footer__owner">
                        <span>{site.title}</span><span>&nbsp;&copy;2025</span>
                    </div>
                    <div className="footer__topics">
                          <Link to="/colophon">Colophon</Link>
                          <Link to="/license">License</Link>
                    </div>
                    <div className="footer__social">
                        <button 
                            onClick={() => window.open(linkedinUrl, '_blank')}
                            aria-label="LinkedIn link"
                            className="icomoon-linkedin"
                            type="button"
                        />
                        <button 
                            onClick={() => window.open(githubUrl, '_blank')}
                            className="icomoon-github"
                            aria-label="Github link"
                            type="button"
                        />
                    </div>
                </footer>
            </div>
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
